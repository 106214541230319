import axios from 'axios';

export async function getAllTenant() {
  const response = await axios.get(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/tenant/getall', {
   
  });
  return response.data;
}
export async function getTenant(id) {
  const response = await axios.get(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/tenant/get?id=' + id, {
   
  });
  return response.data;
}

export async function addTenant(data) {

  const response = await axios.post(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/tenant', data, {
   
  });

  return response;
}

export async function updateTenant(data) {
  const response = await axios.put(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/tenant', data, {
    
  });

  return response;
}

export async function deleteTenant(id) {
  const response = await axios.delete(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/tenant?id=' + id, {
   
  });

  return response;
}