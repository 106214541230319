import React, { useState } from 'react';
import {
  TeamOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Button, Layout, Menu, theme } from 'antd';

import Tenant from "./components/Tenant";
import Organization from "./components/Organization";
import Resource from "./components/Resource";

const { Header, Content, Footer, Sider } = Layout;


function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem(
    <Link to="/" rel="noopener noreferrer">
      Connector
    </Link>
    , '1', <TeamOutlined />),
  // getItem('Team', 'sub2', <TeamOutlined />,
  //   [
  //     getItem('Team 1', '6'),
  //     getItem('Team 2', '8')
  //   ]),
  // getItem('Files', '9', <FileOutlined />),
];
const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Router basename="/">
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <div className="demo-logo-vertical" />
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Tenant />} />
              <Route path="/tenant/:tenantId/organization" element={<Organization />} />
              <Route path="/tenant/:tenantId/organization/:organizationId/resource" element={<Resource />} />
            </Routes>

          </Content>
          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            Connector App ©{new Date().getFullYear()} Created by Connector Team
          </Footer>
        </Layout>
      </Layout>
    </Router>

  );
};
export default App;