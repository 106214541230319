import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb } from "antd";
import { Input, Skeleton, Button, notification, message, Flex, Table, Space, Modal, Tooltip, Pagination } from 'antd';
import { LeftCircleTwoTone, SearchOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleFilled, PlusCircleTwoTone, EditTwoTone } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
//api
import { getAllResourceInOrganization } from './../api/organization';

import CorrectData from './Resource/CorrectData'
import CorrectDataAddUpdate from './Resource/CorrectDataAddUpdate'

import { pageOptions } from './../constants/utils'
import { correctDataCreateUpdate } from './../api/systemmonitoring'
import Highlighter from 'react-highlight-words';

const Resource = () => {

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const { confirm } = Modal;
    const { tenantId, organizationId } = useParams();
    const [resource, setResource] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        filterDropdownProps: {
            onOpenChange(open) {
                if (open) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        // {
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Display name',
            dataIndex: 'displayName',
            key: 'displayName',
            ...getColumnSearchProps('displayName'),
            sorter: (a, b) => a.displayName.length - b.displayName.length,
            render: (text) => text,
        },
        {
            title: 'Mail',
            dataIndex: 'mail',
            key: 'mail',
            ...getColumnSearchProps('mail'),
            sorter: (a, b) => a.mail.length - b.mail.length,
            render: (text) => text,
        },
        {
            title: 'UserPrincipalName',
            dataIndex: 'userPrincipalName',
            key: 'userPrincipalName',
            ...getColumnSearchProps('userPrincipalName'),
            sorter: (a, b) => a.userPrincipalName.length - b.userPrincipalName.length,
            render: (text) => text,
        },
        {
            title: 'Correct Data',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip placement="bottom" title={'Create or update case'}>
                        <Button type="dashed" onClick={() => {
                            // showCorrectCreateUpdateConfirm(record.adId)
                            setResource(record);
                            setIsCorrectDataAddUpdateOpen(true);
                        }}>
                            <PlusCircleTwoTone twoToneColor="#1677ff" />
                            <EditTwoTone />

                        </Button>


                    </Tooltip>
                    <Tooltip placement="bottom" title={'Deletion case'}>

                        <Button type="dashed" onClick={() => {
                            setResource(record);
                            setIsCorrectDataOpen(true);
                        }}>
                            <CloseCircleTwoTone twoToneColor="#eb2f96" />

                        </Button>
                    </Tooltip>
                    {/* <Button onClick={() => {
                        // showDeleteConfirm(record.id)
                    }} type="dashed" >
                        <DeleteTwoTone />
                    </Button> */}
                </Space>
            ),
        },
    ];

    const showCorrectCreateUpdateConfirm = (id) => {
        confirm({
            title: 'Execute correct data for create and update?',
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    var data = {
                        tenantId: tenantId,
                        organizationId, organizationId,
                        adId: id
                    }

                    const response = await correctDataCreateUpdate(data);
                    api['success']({
                        message: 'Executed',
                        description:
                            `${response.data.numberOfItemExecuted} reservations is executed`,
                    });

                    console.log('Response:', response.data);
                } catch (error) {
                    console.error('Error:', error);
                    api['error']({
                        message: 'Executed failed',
                        description:
                            `${error?.response?.data.message}`,
                    });
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);


    const [isCorrectDataOpen, setIsCorrectDataOpen] = useState(false);
    const [isCorrectDataAddUpdateOpen, setIsCorrectDataAddUpdateOpen] = useState(false);

    const handleCancel = () => {
        setIsCorrectDataOpen(false);
        setIsCorrectDataAddUpdateOpen(false);
        fetchData(organizationId)
    };



    async function fetchData(orgId) {
        try {
            const res = await getAllResourceInOrganization(orgId);
            setData(res);
            setLoading(false);
        } catch (error) {
            message.error(error?.response?.data.message);
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData(organizationId);
    }, []);

    return <>
        {contextHolder}
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {
                navigate(`/tenant/${tenantId}/organization`);
            }}>{tenantId}</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {
                navigate(`/tenant/${tenantId}/organization`);
            }}>Organization</Breadcrumb.Item>
            <Breadcrumb.Item>Resources</Breadcrumb.Item>
        </Breadcrumb>

        <Button type="primary" onClick={() => {
            navigate(`/tenant/${tenantId}/organization`)
        }} style={{ marginRight: 10 }}>
            <LeftCircleTwoTone /> Back to Organizations
        </Button>

        <div
            style={{
                paddingTop: 24,
                minHeight: 360,

            }}
        >

            <>
                {
                    loading ? <Skeleton /> : <>
                        <div className="w-full">

                            <Flex gap="middle" vertical>
                                {/* <Flex align="center" gap="middle">
                                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
                                </Flex> */}
                                {
                                    data && data?.resources &&
                                    <>
                                        <Table
                                            columns={columns}
                                            dataSource={data.resources}
                                        />
                                        {/* <Pagination
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={total}
                                            onChange={handlePageChange}
                                            showSizeChanger
                                            pageSizeOptions={pageOptions}
                                        /> */}
                                    </>
                                }

                            </Flex>

                        </div>
                    </>
                }
            </>
        </div>

        

        <CorrectData resource={resource} isCorrectDataOpen={isCorrectDataOpen} handleCancel={handleCancel}/>
        <CorrectDataAddUpdate resource={resource} isCorrectDataAddUpdateOpen={isCorrectDataAddUpdateOpen} handleCancel={handleCancel}/>
    </>

};

export default Resource;