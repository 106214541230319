import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb } from "antd";
import { Skeleton, Button, message, Flex, Table, Space, Tag, Input, Tooltip } from 'antd';
import { AppstoreTwoTone, SearchOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
//api
import { getAllTenant } from './../api/tenant';

import Highlighter from 'react-highlight-words';



const Tenant = () => {

    const navigate = useNavigate();

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        filterDropdownProps: {
            onOpenChange(open) {
                if (open) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        // {
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => a.name.length - b.name.length,
            render: (_, { name, isRcIntegrationEnabled }) => (
                <>
                    {
                        isRcIntegrationEnabled ? <> {name} <Tooltip title="Intergrated RC" placement="bottom"><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip></> : <></>
                    }
                </>
            )
        },
        {
            title: 'Active',
            key: 'isDeleted',
            dataIndex: 'isDeleted',
            sorter: (a, b) => !a.isDeleted,
            render: (_, { isDeleted }) => (
                <>
                    <Tag color={!isDeleted ? 'green' : 'volcano'} key={!isDeleted}>
                        {!isDeleted ? 'Actived' : 'Deactived'}
                    </Tag>
                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                !record.isDeleted ? <>
                    <Space size="middle">
                        <Tooltip placement="bottom" title={'Organization'}>
                            <Button type="dashed" onClick={() => {
                                navigate(`/tenant/${record.id}/organization`);
                            }}>
                                <AppstoreTwoTone />
                            </Button>
                        </Tooltip>

                        {/* <Button onClick={() => {
                        // showDeleteConfirm(record.id)
                    }} type="dashed" >
                        <DeleteTwoTone />
                    </Button> */}
                    </Space>
                </> : <>
                </>
            ),
        },
    ];

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    async function fetchData() {
        try {
            const res = await getAllTenant();
            setData(res);
            setLoading(false);
        } catch (error) {
            message.error(error?.response?.data.message);
            console.error('Error:', error);
        }

    }

    useEffect(() => {

        fetchData();
    });

    return <>
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Tenant</Breadcrumb.Item>
        </Breadcrumb>
        <div
            style={{
                padding: 24,
                minHeight: 360,

            }}
        >

            <>
                {
                    loading ? <Skeleton /> : <>
                        <div className="w-full">

                            <Flex gap="middle" vertical>
                                {/* <Flex align="center" gap="middle">
                                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
                                </Flex> */}
                                <Table
                                    columns={columns}
                                    dataSource={data} />
                            </Flex>

                        </div>
                    </>
                }
            </>
        </div>

    </>

};

export default Tenant;