import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Input, Button, Upload, message, Flex, Row, Tag, Modal, List } from 'antd';
import { UploadOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

//api

import { correctDataDelete } from './../../api/systemmonitoring'

const fileUploadSchema = z.object({
    file: z
        .any()
        .refine((file) => file !== undefined && file !== null, 'File is required')
        .refine((file) => file && file.size < 100 * 1024 * 1024, 'File must be smaller than 100MB')
        .refine(
            (file) => file && (file.name.endsWith('.csv') || file.name.endsWith('.xlsx') || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
            'Only .xlsx, .csv files are allowed',),
});


const CorrectData = ({ resource, isCorrectDataOpen, handleCancel }) => {

    const { tenantId, organizationId } = useParams();

    const [dataResponse, setDataResponse] = useState(null);

    const [fileList, setFileList] = useState([]);

    const {
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm({
        resolver: zodResolver(fileUploadSchema),

    });

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('tenantId', tenantId);
        formData.append('adId', resource.adId);
        formData.append('file', data.file);

        try {
            const response = await correctDataDelete(formData);

            message.success('Done!');

            console.log('Response:', response.data);

            setDataResponse(response.data)
        } catch (error) {
            debugger;
            message.error(error?.response?.data.message);
            console.error('Error:', error);
        }
    };
    const handleModalClose = () => {
        reset(); // Reset form fields
        setDataResponse(null); // Clear previous response
        handleCancel();
        setFileList([]); // Clear uploaded file list
    };
    const handleFileChange = (info) => {
        const file = info.fileList[0]?.originFileObj || null;
        setValue("file", file); // Update file field in react-hook-form
        setFileList(info.fileList); // Update file list for Upload component
        setDataResponse(null);
    };

    useEffect(() => {
    }, []);

    return <>
        <Modal
            title="Delete Data"
            open={isCorrectDataOpen}
            onCancel={handleModalClose}
            footer={null} // Custom footer to place submit button in form
        >


            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
                <Form.Item
                    label="Resource"
                >
                    <Input disabled value={resource?.displayName} />
                </Form.Item>

                <Form.Item
                    label="Upload File"
                    validateStatus={errors.file ? 'error' : ''}
                    help={errors.file?.message}
                >
                    <Upload
                        beforeUpload={() => false} // Prevent default upload behavior
                        fileList={fileList} // Bind file list to Upload component
                        onChange={handleFileChange} // Handle file change
                        maxCount={1} // Allow only one file
                    >
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>

                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            {
                dataResponse && <>
                    <>
                        {/*<Row>Number of reservations</Row>
                         <Flex gap="4px 0" wrap>
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                {dataResponse?.activeDatas.length ?? 0} Active
                            </Tag>
                            <Tag icon={<CloseCircleOutlined />} color="error">
                                {dataResponse?.deleteDatas.length ?? 0} Delete
                            </Tag>
                        </Flex> */}
                        {/* <List
                            itemLayout="vertical"
                            size="small"
                            pagination={{
                                onChange: (page) => {
                                    console.log(page);
                                },
                                pageSize: 3,
                            }}
                            dataSource={dataResponse?.activeDatas}
                            header={
                                <Tag icon={<CheckCircleOutlined />} color="success">
                                    {dataResponse?.activeDatas.length ?? 0} Active
                                </Tag>
                            }

                            renderItem={(item) => (
                                <List.Item
                                    key={item}
                                >
                                    {item}
                                </List.Item>
                            )}
                        /> */}
                        <List
                            itemLayout="vertical"
                            size="small"
                            pagination={{
                                onChange: (page) => {
                                    console.log(page);
                                },
                                pageSize: 3,
                            }}
                            dataSource={dataResponse?.deleteDatas}
                            header={
                                <Tag icon={<CloseCircleOutlined />} color="error">
                                    {dataResponse?.deleteDatas.length ?? 0} Delete
                                </Tag>
                            }

                            renderItem={(item) => (
                                <List.Item
                                    key={item}

                                >
                                    {item}
                                </List.Item>
                            )}
                        />

                    </>

                </>
            }
        </Modal>
    </>

};

export default CorrectData;