import axios from 'axios';

export async function getAllOrganization() {
  const response = await axios.get(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/organization/getall', {

  });
  return response.data;
}
export async function getAllOrganizationInTenant(id) {
  const response = await axios.get(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/tenant/getallorganization?tenantId=' + id, {

  });
  return response.data;
}

export async function getAllResourceInOrganization(id) {
  //&pageSize=${pageSize}&pageNumber=${pageNumber}&keyWord=${keyword}
  const query = `?organizationId=${id}`
  const response = await axios.get(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/organization/getallresource' + query, {

  });
  return response.data;
}


export async function getOrganization(id) {
  const response = await axios.get(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/organization/get?id=' + id, {
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY_VALUE}`
    }
  });
  return response.data;
}

export async function addOrganization(data) {

  const response = await axios.post(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/organization', data, {
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY_VALUE}`
    }
  });

  return response;
}

export async function updateOrganization(data) {
  const response = await axios.put(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/organization', data, {
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY_VALUE}`
    }
  });

  return response;
}

export async function deleteOrganization(id) {
  const response = await axios.delete(process.env.REACT_APP_CONNECTOR_SYNC + '/office-place/organization?id=' + id, {
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY_VALUE}`
    }
  });

  return response;
}