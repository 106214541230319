import React, { useEffect, } from "react";
import { useParams } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

//api

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 14,
        },
    },
};

const OrganizationConfig = ({ config }) => {

    const [form] = Form.useForm();
    const variant = Form.useWatch('variant', form);

    return <>

        <Form
            {...formItemLayout}
            form={form}
            variant={variant || 'filled'}
            style={{
                maxWidth: 400,
            }}
            initialValues={{
                variant: 'filled',
            }}
        >
            <Form.Item
                label="Name"
            >
                <Input disabled value={config.name} />
            </Form.Item>


        </Form>

    </>

};

export default OrganizationConfig;