import axios from 'axios';


export async function correctDataDelete(data) {

  const response = await axios.post(process.env.REACT_APP_CONNECTOR_SYNC + '/system-monitoring/correct-data-delete', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response;
}

export async function correctDataCreateUpdate(data) {

  const response = await axios.post(process.env.REACT_APP_CONNECTOR_SYNC + '/system-monitoring/correct-data-create-update', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response;
}

// export async function correctDataCreateUpdate(data) {
//   const response = await axios.post(process.env.REACT_APP_CONNECTOR_SYNC + '/system-monitoring/correct-data-create-update', data, {
//   });

//   return response;
// }

