import React, { useState, useEffect } from 'react';
import { TeamOutlined, LeftCircleTwoTone, GoldTwoTone, EditOutlined, EllipsisOutlined, SettingTwoTone } from '@ant-design/icons';
import { Card, Modal, Skeleton, Breadcrumb, Tooltip, Button, message, Row, Col } from 'antd';
import { useParams, useNavigate } from "react-router-dom";
//api
import { getAllOrganizationInTenant } from '../api/organization';

import OrganizationConfig from './Organization/OrganizationConfig';

const { Meta } = Card;

const Organization = () => {

    const navigate = useNavigate();

    const { tenantId } = useParams();
    const [data, setData] = useState(null);
    const [organizationConfig, setOrganizationConfig] = useState(null);
    const [isShowConfig, setIsShowConfig] = useState(false);
    const [loading, setLoading] = useState(false);

    async function fetchData() {

        try {
            const res = await getAllOrganizationInTenant(tenantId);
            setData(res);
            setLoading(false);
        } catch (error) {
            message.error('fetchData failed!');
            console.error(error?.response?.data.message);
        }
    }

    const handleCancel = () => {
        setIsShowConfig(false);
    };

    useEffect(() => {

        fetchData();
    }, []);

    return <>
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {
                navigate(`/`);
            }}>{tenantId}</Breadcrumb.Item>
            <Breadcrumb.Item>Organization</Breadcrumb.Item>
        </Breadcrumb>
        <Button type="primary" onClick={() => {
            navigate('/')
        }} style={{ marginRight: 10 }}>
            <LeftCircleTwoTone /> Back to Tenants
        </Button>
        <div
            style={{
                paddingTop: 12,
                minHeight: 360,

            }}
        >
            {
                loading ? <Skeleton /> : <>
                    <div className="w-full">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {
                                data && data.map((item, index) => {
                                    return <>
                                        <Col className="gutter-row" span={6} style={(index + 1) / 4 != 0 ? { paddingTop: 16 } : {}}>
                                            <Card
                                                style={{
                                                    width: 350,
                                                }}
                                                // cover={
                                                //     <img
                                                //         alt="example"
                                                //         src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                                                //     />
                                                // }
                                                actions={[
                                                    <>
                                                        <Tooltip placement="bottom" title="Resources">
                                                            <GoldTwoTone twoToneColor="#eb2f96" key="setting" onClick={() => {
                                                                navigate(`/tenant/${tenantId}/organization/${item.id}/resource`);
                                                            }} />
                                                        </Tooltip>
                                                    </>
                                                    ,
                                                    // <>
                                                    //     <Tooltip placement="bottom" title="Config">
                                                    //         <SettingTwoTone onClick={() => {
                                                    //             setOrganizationConfig(item);
                                                    //             setIsShowConfig(true);
                                                    //         }} />

                                                    //     </Tooltip>
                                                    // </>,
                                                    // <EllipsisOutlined key="ellipsis" onClick={() => {

                                                    // }} />,
                                                ]}
                                            >
                                                <Meta
                                                    avatar={<TeamOutlined color="#eb2f96" />}
                                                    title={item.name}
                                                    description={item.modeType}
                                                />
                                            </Card>
                                        </Col>

                                    </>
                                })
                            }

                        </Row>

                    </div>
                </>
            }

            <Modal
                title="Organization Config"
                open={isShowConfig}
                onCancel={handleCancel}
                footer={null} // Custom footer to place submit button in form
            >
                <OrganizationConfig config={organizationConfig} />
            </Modal>
        </div>
    </>

};

export default Organization;